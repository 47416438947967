//React
import React from "react"
import { connect } from "react-redux"
import { navigate } from "@reach/router"

//Gatsby
import { Link } from "gatsby"

//Amplify
import Amplify from "@aws-amplify/core"
import Auth from "@aws-amplify/auth"
import { amplifyconfig } from "../../utils/config"

//Actions
import { getLoginData, updateUserProfile } from "../../actions/userManagement"

//Components
import PasswordResetRules from "./passwordResetRules"

//Icons
import { FaCheck, FaTimes, FaSpinner } from "react-icons/fa"

//Redux
const mapStateToProps = ({ isLoading }) => {
  return { isLoading }
}

const mapDispatchToProps = {
  getLoginData,
  updateUserProfile,
}

//Configure
Amplify.configure(amplifyconfig)

class App extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      email: "",
      password: "",
      password2: "",
      confirmationcode: "",
      codeScreen: false,
      isFinished: false,
      inApp: false,
      loading: false,
      error: [],
    }
  }

  componentDidMount() {
    console.log("this.props.userobject.user")
    console.log(this.props.userobject.user)
    this.setState({
      email: this.props.userobject.user.challengeParam.userAttributes.email,
    })
  }

  resetForm = () => {
    this.setState({
      email: "",
      password: "",
      password2: "",
      confirmationcode: "",
      codeScreen: false,
      loading: false,
      error: [],
    })
  }

  handleUpdate = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }
  handleSubmit = async () => {
    // const { username, email, phone_number } = getInfoFromUserInput()
    // const loggedUser = await Auth.completeNewPassword(
    //   this.props.userobject.user, // the Cognito User Object
    //   this.state.password, // the new password
    //   // OPTIONAL, the required attributes
    //   {
    //     email: this.state.email,
    //   }
    // )

    console.log("***** starting Auth.completeNewPassword")

    Auth.completeNewPassword(
      this.props.userobject.user, // the Cognito User Object
      this.state.password, // the new password
      // OPTIONAL, the required attributes
      {
        email: this.state.email,
      }
    )
      .then((user) => {
        console.log("###forceNewPassword - completeNewPassword - auth - user")
        console.log(user)
        // this.props.updateUserProfile({ emailverified: true })
        this.props.getLoginData(user, true, true)
      })
      .catch((error) => {
        console.log("****forceNewPassword - completeNewPassword - auth - error")
        console.log(error)
        this.setState({ error: error })
      })
  }

  render() {
    const { isLoading } = this.props

    return (
      <form class="form-signin">
        {this.state.error.message ? (
          <div class="alert alert-danger text-center" role="alert">
            {this.state.error.message}
          </div>
        ) : null}
        <h3 class="form-signin-heading text-center pb-2">Change Password</h3>

        {this.state.email ? (
          <div>
            <p class="password-instructions text-center">
              Before getting started, please change your password.
            </p>

            <input
              type="password"
              id="input-password"
              name="password"
              class="form-control mb-2"
              placeholder="Enter new password"
              onChange={this.handleUpdate}
              required=""
              autoFocus=""
            />
            <input
              type="password"
              id="input-password2"
              name="password2"
              class="form-control mb-2"
              placeholder="Confirm password"
              onChange={this.handleUpdate}
              required=""
              autoFocus=""
            />

            <PasswordResetRules
              password={this.state.password}
              password2={this.state.password2}
            />

            <div class="password-attributes-message text-center">
              {this.state.password === this.state.password2 ? (
                <button
                  type="button"
                  class="btn btn-primary btn-block white-1 mt-2 app-pw-reset-submit d-flex flex-row align-items-center justify-content-center text-center"
                  onClick={this.handleSubmit}
                >
                  Save
                  {isLoading ? (
                    <div className="pl-2">
                      <FaSpinner size={16} className="icon-spin" />
                    </div>
                  ) : null}
                </button>
              ) : (
                <button
                  type="button"
                  class="btn btn-primary btn-block mt-2 white-1 app-pw-reset-submit app-pw-reset-disabled"
                >
                  Save
                </button>
              )}
            </div>
          </div>
        ) : (
          <p class="password-instructions text-center">
            Oops, we weren't able to get your email address. Please{" "}
            <Link to="/app/password" onClick={this.resetForm}>
              begin again.
            </Link>
          </p>
        )}
      </form>
    )
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(App)
// export default App
