//React
import React from "react"
import { connect } from "react-redux"
import { navigate } from "@reach/router"

//Gatsby
import { Link } from "gatsby"

//Amplify
import Amplify from "@aws-amplify/core"
import Auth from "@aws-amplify/auth"
import { amplifyconfig } from "../../utils/config"

//Actions
import { loginUser } from "../../actions/userManagement"
import { loadNewTemplate } from "../../actions/createPublisher"

//Components
import ForceNewPassword from "./forceNewPassword"
import Loading from "../loading"

//Packages
import parse from "url-parse"

//Icons
import { FaSpinner } from "react-icons/fa"

//Redux
const mapStateToProps = ({ userlogin, usererror, isLoading, loginMessage }) => {
  return { userlogin, usererror, isLoading, loginMessage }
}

const mapDispatchToProps = {
  loginUser,
  loadNewTemplate,
}

//Configure
Amplify.configure(amplifyconfig)

class Snippet extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      username: ``,
      password: ``,
      error: [],
      forceNewPassword: false,
      pageid: "",
      templateid: "",
    }
    this.handleLogin = this.handleLogin.bind(this)
  }
  componentDidMount() {
    const { loadNewTemplate } = this.props

    const url = parse(window.location.href, true)

    const pageid = url.query.p
    const templateid = url.query.t

    this.setState({ pageid, templateid })

    console.log("componentDidMount - pageid")
    console.log(pageid)
    console.log("componentDidMount - templateid")
    console.log(templateid)

    Auth.currentAuthenticatedUser()
      .then((user) => {
        console.log("actions - checkUserAuth - user")
        console.log(user)
        if (user) {
          if (pageid && pageid === "edit" && templateid) {
            console.log("redirect-loggedin user ***")
            loadNewTemplate(templateid, "image")
          }
          navigate("/app/publisher/")
        }
      })
      .catch((error) => {
        console.log("actions - checkUserAuth - error")
        console.log(error)
      }) //end get Auth token
  }

  handleUpdate = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  handleLogin() {
    const { username, password, pageid, templateid } = this.state

    console.log("handleLogin - this.state")
    console.log(this.state)

    this.props.loginUser(username, password, {
      pageid,
      templateid,
    })
  }

  render() {
    const { userlogin, usererror, isLoading, loginMessage } = this.props

    return userlogin &&
      userlogin.action &&
      userlogin.action === "forceNewPassword" ? (
      <ForceNewPassword userobject={userlogin} />
    ) : (
      <form className="form-signin w-100" autoComplete="off">
        {usererror && usererror.message ? (
          <div className="alert alert-danger text-center" role="alert">
            {usererror.message}
          </div>
        ) : null}
        {loginMessage ? (
          <div className="alert alert-info text-center" role="alert">
            {loginMessage}
          </div>
        ) : null}

        <div className="form-signin-heading display-3 text-center pb-4">
          Sign in
        </div>
        {/* <label className="sr-only">Email</label> */}

        <input
          className="form-control mb-2"
          onChange={this.handleUpdate}
          placeholder="Email"
          name="username"
          value={this.state.username}
          tabIndex="1"
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              this.handleLogin()
            }
          }}
        />

        {/* <label className="sr-only">Password</label> */}
        <input
          className="form-control mb-2"
          onChange={this.handleUpdate}
          placeholder="Password"
          name="password"
          value={this.state.password}
          type="password"
          tabIndex="2"
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              this.handleLogin()
            }
          }}
        />

        <button
          type="button"
          className="btn btn-primary btn-block mt-2 app-button-signin d-flex align-items-center justify-content-center"
          onClick={this.handleLogin}
          id="app-login-submit"
          tabIndex="3"
        >
          Sign in
          {isLoading ? (
            <div className="pl-2">
              <FaSpinner size={16} className="icon-spin" />
            </div>
          ) : null}
        </button>
        <Link
          className="btn btn-block my-3 dkblue-1"
          to="/app/password"
          tabIndex="4"
        >
          Forgot password?
        </Link>
        <div class="small gray-1 login-terms pt-4 text-center">
          <hr size={1} />
          By signing in, you agree to the Storylava{" "}
          <a target="_blank" href="https://storylava.com/about/terms/">
            Terms
          </a>{" "}
          and{" "}
          <a target="_blank" href="https://storylava.com/about/privacy/">
            Privacy Policy
          </a>
        </div>
      </form>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Snippet)
